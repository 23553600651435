import React, { PureComponent } from 'react';
import './styles.scss';

class DistractorComponent extends PureComponent {
    render() {
        return(
            <div id="distractor"></div>
        );
    }
}

export default DistractorComponent;