import React, { Component } from 'react';
import DistractorComponent from './DistractorComponent';

class DistractorContainer extends Component {
    render() {
        return(
            <DistractorComponent />
        );
    }
}

export default DistractorContainer;